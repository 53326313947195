<template>
  <section>
    <!-- Table Top -->
    <b-sidebar
      id="add-admin-user-sidebar"
      v-model="openSideBar"
      :title="sideBarTitle"
      bg-variant="white"
      right
      shadow
      backdrop
      aria-controls="add-admin-user-sidebar"
      :aria-expanded="openSideBar"
      width="40%"
    >
      <validation-observer
        ref="observer"
        v-slot="{ pristine, invalid, handleSubmit }"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-card>
            <admin-user-form
              ref="form"
              @updateFirstName="updateFirstName"
              @updateLastName="updateLastName"
              @updateEmail="updateEmail"
              @updatePhone="updatePhone"
            />  
            <b-row class="mt-5">
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="reset"
                  variant="outline-dark"
                  @click="clearForm"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="submit"
                  variant="primary"
                >
                  <b-spinner
                    v-if="isUpdating" 
                    small
                  />
                  <span
                    v-if="isUpdating"
                  > Updating...</span>
                  <span v-else>Submit</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-sidebar>
  </section>
</template>
    
  <script>
    import {
      BRow,
      BCol,
      BCard,
      BSidebar,
      BButton,
      BSpinner,
      BForm
    } from "bootstrap-vue";
    
    import { ValidationObserver } from "vee-validate";
    import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
    import AdminUserForm from './AdminUserForm.vue';
    import { User, userRoles, userCreationErrorCodes } from '@models';
    //eslint-disable-next-line
    import { required, email, min, max, numeric } from "@validations";
    import usersService from "@services/usersService";

    export default {
      name: 'AddAdminUserSideBar',
      components: {
        BCard,
        BRow,
        BCol,
        BSidebar,
        BButton,
        BSpinner,
        BForm,
        ValidationObserver,
        AdminUserForm
      },
      props: {
        open: {
          type: Boolean,
          default: false
        },
        roleId: {
          type: Number,
          default: userRoles.ADMIN
        }
      },
      data() {
        return {
          isUpdating: false,
          openSideBar: false,
          user: new User(),
          force: false,
        };
      },
      computed: {
        sideBarTitle() {
          return this.roleId === userRoles.ADMIN ? 'Add Admin User' : 'Add Program Admin User';
        }
      },
      watch: {
        open(n){
          if(n) {
            this.openSideBar = this.open;
          }
        },
        openSideBar(n) {
          this.$emit('toggleSideBar', n);
        }
      },
      methods: {
        async onSubmit () {
          try {
            this.isUpdating = true;
            if (this.roleId === userRoles.ADMIN) {
              await usersService.createAdmin({
                ...this.user,
                force: this.force
              });
              this.$toast(makeSuccessToast('Admin User created successfully.'));
            } else {
              await usersService.createProgramAdmin({
                ...this.user,
              });
              this.$toast(makeSuccessToast('Program Admin User created successfully.'));
            }
            this.$emit('userAdded');
            this.clearForm();
          } catch (e) {
            const { status, data } = e.response;
            if (status === 422 && data.errors) {
              //server may respond with vaidation errors
              this.$refs.observer.setErrors(data.errors);
            } else if (status === 409 && data.code === userCreationErrorCodes.EXISTING_USER_ALLOWED) {
              // Force create admin
              this.$bvModal
                .msgBoxConfirm(data.message, {
                  title: 'Overwrite Alert',
                  size: 'sm',
                  okVariant: 'primary',
                  okTitle: 'Continue',
                  cancelTitle: 'Cancel',
                  cancelVariant: 'outline-secondary',
                  hideHeaderClose: false,
                  centered: true,
                })
                .then(async value => {
                  if (value) {
                    this.force = true;
                  }
                });
            } else {
              this.$toast(makeErrorToast(data.message));
              this.$log.error(e);
            }

          } finally {
            this.isUpdating = false;
          }
        },
        clearForm() {
          this.$refs.observer.reset();
          this.$refs.form.clearForm();
          this.closeSideBar();
        },
        closeSideBar() {
          this.openSideBar = false;
          this.$emit('close');
        },
        updateFirstName (firstName) {
          this.user = {
            ...this.user,
            first_name: firstName
          };
        },
        updateLastName (lastName) {
          this.user = {
            ...this.user,
            last_name: lastName
          };
        },
        updateEmail (email) {
          this.user = {
            ...this.user,
            email: email
          };
        },
        updatePhone (phone) {
          this.user = {
            ...this.user,
            phone: phone
          };
        }
      }
    };
</script>
    
<style lang="scss">
  $sidebar-header-text-color: #808080;
  .b-sidebar > .b-sidebar-header{
    flex-direction: row-reverse;
    background: #f3f3f3;
    color: $sidebar-header-text-color;

    #add-admin-user-sidebar___title__ {
      font-size: .8em;
      flex: 2;
    }
  }
  .card {
    box-shadow: none;
  }
</style>