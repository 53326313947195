var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"first-name",attrs:{"vid":"first-name","rules":"required","name":"First Name"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"First Name","label-for":"first-name"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"First Name","state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.localFirstName),callback:function ($$v) {_vm.localFirstName=$$v},expression:"localFirstName"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"last-name",attrs:{"vid":"last-name","rules":"required","name":"Last Name"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('b-form-input',{attrs:{"placeholder":"Last Name","state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.localLastName),callback:function ($$v) {_vm.localLastName=$$v},expression:"localLastName"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"phone-number",attrs:{"vid":"phone-number","rules":"required|aom_phone","name":"Phone number"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone_number"}},[_c('aom-tel-input',{attrs:{"id":"phone"},model:{value:(_vm.localPhone),callback:function ($$v) {_vm.localPhone=$$v},expression:"localPhone"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"email",attrs:{"vid":"email","rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"placeholder":"Email","state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.localEmail),callback:function ($$v) {_vm.localEmail=$$v},expression:"localEmail"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }