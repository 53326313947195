<template>
  <div>
    <b-row class="mt-2">
      <b-col md="12">
        <validation-provider
          v-slot="validationContext"
          :ref="`first-name`"
          :vid="`first-name`"
          rules="required"
          name="First Name"
        >
          <b-form-group 
            label="First Name"
            label-for="first-name"
          >
            <b-form-input
              v-model="localFirstName"
              type="text"
              placeholder="First Name"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  
    <b-row class="mt-1">
      <b-col md="12">
        <validation-provider
          v-slot="validationContext"
          :ref="`last-name`"
          :vid="`last-name`"
          rules="required"
          name="Last Name"
        >
          <b-form-group 
            label="Last Name"
            label-for="last_name"
          >
            <b-form-input
              v-model="localLastName"
              placeholder="Last Name"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col md="12">
        <validation-provider
          v-slot="validationContext"
          :ref="`phone-number`"
          :vid="`phone-number`"
          rules="required|aom_phone"
          name="Phone number"
        >
          <b-form-group 
            label="Phone"
            label-for="phone_number"
          >
            <aom-tel-input
              id="phone"
              v-model="localPhone"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col md="12">
        <validation-provider
          v-slot="validationContext"
          :ref="`email`"
          :vid="`email`"
          rules="required|email"
          name="Email"
        >
          <b-form-group 
            label="Email"
            label-for="email"
          >
            <b-form-input
              v-model="localEmail"
              placeholder="Email"
              :state="
                getValidationState(
                  validationContext
                )
              "
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>
  
  
<script>
import {
  BRow, 
  BCol, 
  BFormGroup, 
  BFormInput,
  BFormInvalidFeedback
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider } from "vee-validate";
import { getValidationState } from "@/libs/utils";
import {  min, max, required } from "@validations";

export default {
  name:'AdminUserForm',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    BFormInvalidFeedback
  },
  directives: {
    Ripple,
  },
  props: {
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    email: {
      type: String,  
      default: ''
    },
    phone: {
      type: [Number, String],
      default: ''
    }
  },
  data(vm) {
    return {
      localFirstName: vm.firstName,
      localLastName: vm.lastName,
      localEmail: vm.email,
      localPhone: vm.phone
    };
  },
    
  watch:{
    firstName(n) {
      if(n){
        this.localFirstName = n;
      }
    },
    lastName(n) {
      if(n){
        this.localLastName = n;
      }
    },
    phone(n) {
      if(n){
        this.localPhone = n;
      }
    },
    email(n) {
      if(n){
        this.localEmail = n;
      }
    },
    localFirstName(n) {
      this.$emit('updateFirstName', n);
    },
    localLastName(n) {
      this.$emit('updateLastName', n);
    },
    localEmail(n) {
      this.$emit('updateEmail', n);
    },
    localPhone(n) {
      this.$emit('updatePhone', n);
    },
  },
  methods: {
    clearForm() {
      this.localFirstName = '';
      this.localLastName = '';
      this.localEmail = '';
      this.localPhone = '';
    }
  },
  setup() {
    return {
      getValidationState, min, max, required
    };
  }
};
</script> 
  