<template>
  <vue-good-table
    class="mt-3"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    sort-options="{
        enabled: true,
        initialSortBy: {field: 'dateAdded', type: 'asc'}
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Name -->
      <span
        v-if="props.column.field === 'full_name'"
        class="text-nowrap"
      >
        <b-link @click="editAdminUser(props.row)">
          <b-avatar
            variant="primary"
            :text="`${props.row.first_name.charAt(0)}${props.row.last_name.charAt(0)}`"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.full_name }}</span>
        </b-link>
      </span>

      <!-- Column: Status -->
      <span v-else-if="props.column.field === 'status'">
        <b-badge :variant="STATUS_COLOR[userAdminStatus(props.row)]">
          {{ userAdminStatus(props.row) }}
        </b-badge>
      </span>
      <!-- Column: Action -->
      <div
        v-else-if="props.column.field === 'action'"
        cols="12"
        md="2"
        class="vgt-center-align "
      >
        <b-button
          variant="outline-none"
          @click="deleteAdminUser(props.row.id)"
        >
          <feather-icon
            icon="TrashIcon"
            size="20"
          />
        </b-button>
      </div>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BLink
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import {
  STATUS_COLOR,
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import store from "@/store/index";
import usersService from "@services/usersService";
import { makeSuccessToast, makeErrorToast, localDateStringOrDateRangeStringToUtcRange } from "@/libs/utils";
import { userRolesDisplay, userRoles as userRolesModel, userStatusDisplay, userStatus, userStatusSelect, adminRolesDisplay, userRoles } from '@models';
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  name: 'AdminUserList',
  components: {
    BAvatar,
    VueGoodTable,
    BBadge,
    TablePagination,
    BButton,
    BLink
  },
  data() {
    return {
      isLoading: false,
      dir: false,
      columns: [
        
        {
          label: 'Full Name',
          field: 'full_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email',
          },
        },
        {
          label: "Date Added",
          field: "created_at",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Date",
            filterFn: this.dateFilter,
          },
          tdClass: "text-center",
          width: "8em",
          type: "date",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        },
        {
          label: "User Role",
          field: "roleText",
          filterOptions: {
            enabled: true,
            placeholder: "Search Roles",
            filterDropdownItems: adminRolesDisplay,
          },
          width: "10em",
        },
        {
          label: "Status",
          field: "status",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: userStatusSelect,
          },
          width: "8em",
        },
        {
          field: 'action',
          sortable: false,
          width: '6em',
        },
      ],
      columnFilters: [],
      sort: [],
      total: 0,
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
    
  },
  mounted() {
    const elements = document.getElementsByName("vgt-created_at");
    elements.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });
    });
  },
  created() {
    this.loadItems();
  },
  methods: {
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        'status',
        'full_name',
        'email',
        'created_at',
        'roleText'
      ]) {
        if (params.columnFilters[col]) {
          if (col === "roleText") {
            let roleIds = [];
            roleIds.push(params.columnFilters.roleText);
            columnFilters.push({
              field: 'role',
              value: roleIds,
            });
          } else if (col === "created_at") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else if (col === 'status') {
            columnFilters.push({
              field: 'status',
              value: Number(params.columnFilters['status'])
            });
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await usersService.listAdmins({
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { items, total }  = response.data;
        this.total = total;
        const mappedItems = items.map(i => i = { 
          ...i,
          role: this.userRole(i?.user_roles),
          roleText: i.user_roles.filter(r => r.role_id === userRoles.ADMIN || r.role_id === userRoles.PROGRAM_ADMIN_CANDIDATE).map(r => r.role.name).join(', ')
        });
        this.rows = mappedItems;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    userRole(userRoles) {
      if(userRoles.length > 0) {
        const roles = userRoles.map(r => userRolesDisplay[r.role.id]);
        const uniqueRoles = [...new Set(roles)];
        // remove CHAMPION CANDIDATE role if CHAMPION role is existed
        const hasChampionRole = uniqueRoles.findIndex(r => r === userRolesDisplay[userRolesModel.CHAMPION]);
        const hasChampionCandidateRole = uniqueRoles.findIndex(r => r === userRolesDisplay[userRolesModel.CHAMPION_CANDIDATE]);
        if (hasChampionRole !== -1 && hasChampionCandidateRole !== -1) {
          uniqueRoles.splice(hasChampionCandidateRole, 1);
        }
        return uniqueRoles.join(", ");
      }
      return '';
    },
    editAdminUser(id) {
      this.$nextTick(()=> {
        this.$emit('edit', id);
      });
    },
    async deleteAdminUser(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this user? This user will lose all access. ?', {
          title: 'Delete Admin User',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              this.isLoading = true;
              await usersService.deleteAdminUser(id);
              this.loadItems();
              this.$toast(makeSuccessToast('Application deleted successfully.'));
            } catch (e) {
              this.$toast(makeErrorToast('Something went wrong. Try again'));
              this.$log.error(e);
            } finally {
              this.isLoading = false;
            }
          }
        });
    },
    userAdminStatus(user) {
      const admin  = user.user_roles.filter(role => role.role_id === userRolesModel.ADMIN || role.role_id === userRolesModel.PROGRAM_ADMIN_CANDIDATE).shift();
      for (const [, value] of Object.entries(userStatus)) {
        if (Number(value) === Number(admin.status_id)) {
          return userStatusDisplay[value];
        }
      }
      return "";
    }
  },
  setup () {
    const { total, perPage, page } = useAomTableConfig();
    return {
      STATUS_COLOR,
      total, 
      perPage, 
      page
    };
  }   
};
</script>
<style lang="scss">
@import '/src/assets/scss/vue-good-table.scss';
</style>
