<template>
  <b-card>
    <!-- Table Top -->
    <b-row>
      <b-col
        cols="30"
        md="12"
      >
        <div class="d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.add-new-item-sidebar
            variant="primary"
            @click="addAdminUser"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="text-nowrap">Add User</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.add-new-item-sidebar
            variant="primary"
            class="ml-1"
            @click="addProgramAdminUser"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="text-nowrap">Add Program Admin</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- List -->
    <admin-user-list
      ref="adminUserList"
      @edit="editAdminUser"
    />
    <!-- Add User -->
    <add-admin-user
      :role-id="addUserRoleId"
      :open="openAdd"
      @toggleSideBar="clear"
      @userAdded="refreshList"
    />
    <!-- Edit User -->
    <edit-admin-user
      :user="editUser"
      :open="openEdit"
      @toggleSideBar="clear"
      @userUpdated="refreshList"
    />
  </b-card>
</template>

<script>
import {
  BButton,
  BRow, BCol, BCard,
} from 'bootstrap-vue';
import Ripple from "vue-ripple-directive";
import  AdminUserList  from './AdminUserList.vue';
import AddAdminUser from './AddAdminUser.vue';
import EditAdminUser from './EditAdminUser.vue';
import { userRoles } from '@/models';

export default {
  name: 'AdminUsers',
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    AdminUserList,
    AddAdminUser,
    EditAdminUser
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      openAdd: false,
      openEdit: false,
      editUser: undefined,
      addUserRoleId: userRoles.ADMIN,
    };
  },
  methods: {
    editAdminUser(user) {
      this.editUser = user;
      this.openEdit = true;
    },
    addAdminUser() {
      this.addUserRoleId = userRoles.ADMIN;
      this.openAdd = true;
    },
    addProgramAdminUser() {
      this.addUserRoleId = userRoles.PROGRAM_ADMIN_CANDIDATE;
      this.openAdd = true;
    },
    clear(open) {
      if(!open) {
        this.openEdit = false;
        this.openAdd = false;
        this.editUser = undefined;
      }
    },
    refreshList() {
      this.$refs.adminUserList.loadItems();
    }
  }
};
</script>

<style lang="scss" >
.badge {
    font-weight: 400 !important;
}
.vgt-left-align {
    vertical-align: middle !important;
}

</style>
