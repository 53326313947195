<template>
  <section>
    <!-- Table Top -->
    <b-sidebar
      id="edit-admin-user-sidebar"
      v-model="openSideBar"
      :title="sideBarTitle"
      bg-variant="white"
      right
      shadow
      backdrop
      aria-controls="admin-user-sidebar"
      :aria-expanded="openSideBar"
      width="40%"
    >
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.prevent="handleSubmit(onUpdate)">
          <b-card>
            <admin-user-form
              :first-name="updatedUser.first_name"
              :last-name="updatedUser.last_name"
              :phone="updatedUser.phone"
              :email="updatedUser.email"
              @updateFirstName="updateFirstName"
              @updateLastName="updateLastName"
              @updateEmail="updateEmail"
              @updatePhone="updatePhone"
            />  
            <b-row class="mt-5">
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="reset"
                  variant="outline-dark"
                  @click="clearForm"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col
                sm="6"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="submit"
                  variant="primary"
                >
                  <b-spinner
                    v-if="isUpdating" 
                    small
                  />
                  <span
                    v-if="isUpdating"
                  > Updating...</span>
                  <span v-else>Submit</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-sidebar>
  </section>
</template>
      
    <script>
      import {
        BRow,
        BCol,
        BCard,
        BSidebar,
        BButton,
        BSpinner,
        BForm
      } from "bootstrap-vue";
      
      import { ValidationObserver } from "vee-validate";
      import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
      import AdminUserForm from './AdminUserForm.vue';
      import { User } from '@models';
      import usersService from "@services/usersService";

      export default {
        name: 'EditAdminUserSideBar',
        components: {
          BCard,
          BRow,
          BCol,
          BSidebar,
          BButton,
          BSpinner,
          BForm,
          ValidationObserver,
          AdminUserForm
        },
        props: {
          user: {
            type: Object,
            default: () => {}
          },
          open: {
            type: Boolean,
            default: false
          }
        },
        data() {
          return {
            sideBarTitle: 'Edit Admin User',
            isUpdating: false,
            openSideBar: false,
            updatedUser: new User(this.user)
          };
        },
        watch: {
          open(n) {
            if(n) {
              this.openSideBar = this.open;
            }
          },
          openSideBar(n) {
            this.$emit('toggleSideBar', n);
          },
          user (n) {
            if(n) {
              this.updatedUser = n;
            }
          }
        },
        methods: {
          async onUpdate () {
            try {
              this.isUpdating = true;
              const {id} = this.updatedUser;
              await usersService.updateChampionOrAdmin(id, this.updatedUser);
              this.$toast(makeSuccessToast('Admin User updated successfully.'));
              this.$emit("userUpdated");
              this.clearForm();
            } catch (e) {
              const { status, data } = e.response;
              //server may respond with vaidation errors
              if (status === 422 && data.errors) {
                  this.$refs.observer.setErrors(data.errors);
              } 
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            } finally {
              this.isUpdating = false;
            }
          },
          clearForm() {
            this.isUpdating = false;
            this.openSideBar =  false;
            this.updatedUser = new User();
          },
          updateFirstName (firstName) {
            this.updatedUser = {
              ...this.updatedUser,
              first_name: firstName
            };
          },
          updateLastName (lastName) {
            this.updatedUser = {
              ...this.updatedUser,
              last_name: lastName
            };
          },
          updateEmail (email) {
            this.updatedUser = {
              ...this.updatedUser,
              email: email
            };
          },
          updatePhone (phone) {
            this.updatedUser = {
              ...this.updatedUser,
              phone: phone
            };
          }
        }
      };
      </script>
      
      <style lang="scss">
        $sidebar-header-text-color: #808080;
        .b-sidebar > .b-sidebar-header{
          flex-direction: row-reverse;
          background: #f3f3f3;
          color: $sidebar-header-text-color;
      
          #admin-user-sidebar___title__ {
            font-size: .8em;
            flex: 2;
          }
        }
        .card {
          box-shadow: none;
        }
      </style>